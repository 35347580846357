<template>
  <v-container fluid>
    <v-list-item>
      <div v-for="item in items" :key="item.title">
        <div class="mb-2">
          <v-btn
            size="x-large"
            variant="flat"
            block
            color="#26BD5A"
            :href="item.link"
            >{{ item.title }}</v-btn
          >
        </div>
      </div>
    </v-list-item>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Conheça a EMPLOYER",
        link: "https://employer.pt/site/sobre-nos/",
      },
      {
        title: "VAGAS",
        link: "https://employer.pt/site/vagas/",
      },
      {
        title: "Serviços",
        link: "https://employer.pt/site/servicos/",
      },
    ],
  }),
};
</script>

<style></style>
