<template>
  <v-container>
    <v-avatar size="80">
      <v-img cover :src="avatar"></v-img>
    </v-avatar>
    <h2 class="mt-2">{{ title }}</h2>
  </v-container>
</template>

<script>
import logo from "@/assets/images/logo.png";
export default {
  data: () => ({
    avatar: logo,
    title: "Employer - Conectando Pessoas à Oportunidades",
    profile: "@employer.pt",
    link: "https://instagram.com/employer.pt",
  }),
};
</script>
