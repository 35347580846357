<template>
  <router-view />
</template>

<style lang="scss">
@import "./assets/css/colors";
@font-face {
  font-family: IdealistSansLight;
  src: url("./assets/fonts/IdealistSansLight.ttf");
}
@font-face {
  font-family: DaysSansBlack;
  src: url("./assets/fonts/DaysSansBlack.otf");
}
#app {
  font-family: IdealistSansLight, Helvetica, Arial, sans-serif;
  background-color: $primary-color;
  text-align: center;
}
a {
  cursor: pointer;
  text-decoration: none;
}
</style>
