<template>
  <v-container>
    <v-row justify="center">
      <v-col v-for="items in icons" :key="items.name" cols="auto">
        <v-btn
          class="icon"
          color="#0B2C23"
          :icon="items.icon"
          variant="plain"
          :href="items.link"
        ></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    icons: [
      {
        name: "facebook",
        link: "https://www.facebook.com/employerPT/",
        icon: "mdi-facebook",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/employer.pt/",
        icon: "mdi-instagram",
      },
      {
        name: "linkedin",
        link: "",
        icon: "mdi-linkedin",
      },
      {
        name: "web",
        link: "https://www.employer.pt",
        icon: "mdi-web",
      },
    ],
  }),
};
</script>

<style>
.icon {
  font-size: 25px;
}
</style>
