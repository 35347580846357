<template>
  <v-list lines="three" class="css">
    <!-- Logo e Slogan -->
    <Header />

    <!-- Redes Sociais -->
    <SocialMedia />

    <!-- Botoes -->
    <BtnLinks />
  </v-list>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/HeaderLink.vue";
import SocialMedia from "@/components/SocialMedia.vue";
import BtnLinks from "@/components/BtnLinks.vue";

export default {
  name: "HomeView",
  pageTitle: "BIO Employer.pt",
  components: {
    Header,
    SocialMedia,
    BtnLinks,
  },
  // beforeMount() {
  //   document.title = "BIO EMPLOYER";
  // },
};
</script>

<style lang="scss">
.css {
  background-color: #fffff9;
}
</style>
